/* 
  Credits to @Neiwad for the style inspiration
    https://neiwad.dev/
    https://github.com/neiwad/
 */

:root {
  --body-bg: #fff;
  --text-color: #000;
  --separator-color: rgb(189, 194, 196);
  --light-button: rgb(69, 42, 122);
  --dark-button: rgb(189, 194, 196);
  --light-logo-opacity: 1;
  --dark-logo-opacity: 0;
  --text-opacity: 1;
}
.dark_mode {
  --body-bg: #000;
  --text-color: #fff;
  --separator-color: rgb(102, 97, 113);
  --light-button: rgb(102, 97, 113);
  --dark-button: rgb(234, 226, 252);
  --light-logo-opacity: 0;
  --dark-logo-opacity: 1;
  --text-opacity: 0.8;
}

h1,
h2,
h3,
ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
body {
  margin: 0;
  background-color: var(--body-bg);
  color: var(--text-color);
  font-family: 'Fira Sans', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}
main {
  width: 100%;
  max-width: 512px;
  padding: 0px 30px;
  margin: auto;
  margin-bottom: 50px;
  box-sizing: border-box;
}

/* COMMONS */
section {
  margin-top: 50px;
  h2 {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 20px;
  }
}
p {
  line-height: 1.5;
  opacity: var(--text-opacity);
}
a {
  color: inherit;
  white-space: nowrap;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:not(.button)::after {
    content: ' \2192';
  }
}
button {
  all: unset;
  cursor: pointer;
}

/* HEADER */
section.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    border-radius: 100%;
    height: 150px;
    width: 150px;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
  }
  .img-container {
    position: relative;
    height: 150px;
    margin-bottom: 15px;
    .light-logo,
    .dark-logo {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .dark-logo {
      opacity: var(--dark-logo-opacity);
    }
  }
  h1 {
    font-size: 30px;
    i {
      font-size: 15px;
    }
  }
  .actions {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 20px;
    border-radius: 30px;
    cursor: pointer;
    svg {
      align-self: center;
      flex-shrink: 0;
      fill: var(--text-color);
    }
  }
  .toggler {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 30px;
    cursor: pointer;
    color: var(--button-color);

    img {
      height: 24px;
      width: 24px;
    }
    #light {
      fill: var(--light-button);
    }
    #dark {
      fill: var(--dark-button);
    }
    svg {
      align-self: center;
      flex-shrink: 0;
    }
  }
  .separator {
    color: var(--separator-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-left: 4px;
    margin-right: 4px;
  }
}

/* ABOUT */
section.about p {
  font-weight: 200;
}

/* CURRENTLY */
section.currently p {
  font-weight: 200;
  svg {
    fill: var(--text-color);
    margin-right: 5px;
  }
}

/* SKILLS */
section.skills {
  .title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  subsection {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  skill {
    color: var(--body-bg);
    background-color: var(--text-color);
    border-radius: 15px;
    padding: 5px 10px;
  }
}

/* XPERIENCES */
section.experiences {
  .title {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: start;
    i {
      font-size: 14px;
      font-weight: 200;
    }
  }
  .description {
    font-size: 15px;
    font-weight: 200;
  }
}

/* PROJECTS */
section.projects {
  subsection {
    .subsection-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 1em;
      margin-top: 0.5em;
    }
  }
  .project {
    .title {
      font-size: 16px;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: start;
      i {
        font-size: 14px;
        font-weight: 200;
      }
    }
    .category {
      margin-top: 5px;
    }
    .description {
      font-size: 15px;
      font-weight: 200;
    }
  }
}

/* STUDIES */
section.studies .study {
  h3 {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  i {
    font-size: 14px;
    font-weight: 200;
    white-space: nowrap;
  }
  .description {
    font-size: 15px;
    font-weight: 200;
  }
}

/* LINKS */
section.links li {
  margin-bottom: 10px;
  span {
    width: 120px;
    display: inline-block;
    opacity: 0.8;
    font-weight: 200;
  }
}

@media print {
  @page {
    margin: 10mm;
  }
  body {
    -webkit-print-color-adjust: exact;
  }
  main {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
  section.header {
    .toggler,
    .actions {
      display: none !important;
    }
    .img-container {
      height: 100px;
    }
    img {
      height: 100px;
      width: 100px;
    }
  }
  :root {
    --body-bg: #fff;
    --text-color: #000;
  }
  .dark_mode {
    --body-bg: #fff;
    --text-color: #000;
    --text-opacity: 1;
  }
  section.currently {
    display: none;
  }
}
