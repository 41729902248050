:root {
  --body-bg: #fff;
  --text-color: #000;
  --separator-color: #bdc2c4;
  --light-button: #452a7a;
  --dark-button: #bdc2c4;
  --light-logo-opacity: 1;
  --dark-logo-opacity: 0;
  --text-opacity: 1;
}

.dark_mode {
  --body-bg: #000;
  --text-color: #fff;
  --separator-color: #666171;
  --light-button: #666171;
  --dark-button: #eae2fc;
  --light-logo-opacity: 0;
  --dark-logo-opacity: 1;
  --text-opacity: .8;
}

h1, h2, h3, ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

body {
  background-color: var(--body-bg);
  color: var(--text-color);
  margin: 0;
  font-family: Fira Sans, sans-serif;
  transition: background-color .3s, color .3s;
}

main {
  box-sizing: border-box;
  width: 100%;
  max-width: 512px;
  margin: auto auto 50px;
  padding: 0 30px;
}

section {
  margin-top: 50px;
}

section h2 {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 400;
}

p {
  opacity: var(--text-opacity);
  line-height: 1.5;
}

a {
  color: inherit;
  white-space: nowrap;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:not(.button):after {
  content: " →";
}

button {
  all: unset;
  cursor: pointer;
}

section.header {
  flex-direction: column;
  align-items: center;
  display: flex;
}

section.header img {
  -o-transition: opacity .3s ease-in-out;
  border-radius: 100%;
  width: 150px;
  height: 150px;
  transition: opacity .3s ease-in-out;
}

section.header .img-container {
  height: 150px;
  margin-bottom: 15px;
  position: relative;
}

section.header .img-container .light-logo, section.header .img-container .dark-logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

section.header .img-container .dark-logo {
  opacity: var(--dark-logo-opacity);
}

section.header h1 {
  font-size: 30px;
}

section.header h1 i {
  font-size: 15px;
}

section.header .actions {
  cursor: pointer;
  border-radius: 30px;
  align-items: center;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

section.header .actions svg {
  fill: var(--text-color);
  flex-shrink: 0;
  align-self: center;
}

section.header .toggler {
  cursor: pointer;
  color: var(--button-color);
  border-radius: 30px;
  align-items: center;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

section.header .toggler img {
  width: 24px;
  height: 24px;
}

section.header .toggler #light {
  fill: var(--light-button);
}

section.header .toggler #dark {
  fill: var(--dark-button);
}

section.header .toggler svg {
  flex-shrink: 0;
  align-self: center;
}

section.header .separator {
  color: var(--separator-color);
  margin-left: 4px;
  margin-right: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

section.about p, section.currently p {
  font-weight: 200;
}

section.currently p svg {
  fill: var(--text-color);
  margin-right: 5px;
}

section.skills .title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
}

section.skills subsection {
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  display: flex;
}

section.skills skill {
  color: var(--body-bg);
  background-color: var(--text-color);
  border-radius: 15px;
  padding: 5px 10px;
}

section.experiences .title {
  justify-content: space-between;
  align-items: start;
  font-size: 16px;
  font-weight: 400;
  display: flex;
}

section.experiences .title i {
  font-size: 14px;
  font-weight: 200;
}

section.experiences .description {
  font-size: 15px;
  font-weight: 200;
}

section.projects subsection .subsection-title {
  margin-top: .5em;
  margin-bottom: 1em;
  font-size: 20px;
  font-weight: 600;
}

section.projects .project .title {
  justify-content: space-between;
  align-items: start;
  font-size: 16px;
  font-weight: 400;
  display: flex;
}

section.projects .project .title i {
  font-size: 14px;
  font-weight: 200;
}

section.projects .project .category {
  margin-top: 5px;
}

section.projects .project .description {
  font-size: 15px;
  font-weight: 200;
}

section.studies .study h3 {
  justify-content: space-between;
  align-items: start;
  font-size: 16px;
  font-weight: 400;
  display: flex;
}

section.studies .study i {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 200;
}

section.studies .study .description {
  font-size: 15px;
  font-weight: 200;
}

section.links li {
  margin-bottom: 10px;
}

section.links li span {
  opacity: .8;
  width: 120px;
  font-weight: 200;
  display: inline-block;
}

@media print {
  @page {
    margin: 10mm;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  main {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  section.header .toggler, section.header .actions {
    display: none !important;
  }

  section.header .img-container {
    height: 100px;
  }

  section.header img {
    width: 100px;
    height: 100px;
  }

  :root {
    --body-bg: #fff;
    --text-color: #000;
  }

  .dark_mode {
    --body-bg: #fff;
    --text-color: #000;
    --text-opacity: 1;
  }

  section.currently {
    display: none;
  }
}

/*# sourceMappingURL=index.d3e4d109.css.map */
